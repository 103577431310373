<template>
  <ion-text>
    <h2 class="ion-margin-horizontal ion-no-margin ion-margin-top">Productos relacionados</h2>
  </ion-text>
  <!-- TODO: [UI] Make all slides same height (rdelcampo) -->
  <ion-slides :options="{ slidesPerView: 2.2, spaceBetween: 15 }" class="ion-padding ion-margin-bottom">
    <ion-slide v-for="upsell in upsells" :key="upsell['name']">
      <ion-card button class="ion-no-margin"
                :router-link="'/carta/' + upsell['categories'][0]['slug'] + '/' + upsell['slug']"
                routerDirection="forward"
                :key="upsell['id']">
        <ion-img class='product-img ion-margin' :src="upsell['images'][0]['src']" :alt="upsell['name']"/>
        <ion-card-header>
          <ion-card-title>{{ upsell['name'] }}</ion-card-title>
          <ion-card-subtitle>
            <p v-if="upsell['stock_status'] === 'outofstock'">Agotado</p>
            <p v-else>{{ $currency(upsell['price']) }}</p>
          </ion-card-subtitle>
        </ion-card-header>
      </ion-card>
    </ion-slide>
  </ion-slides>
</template>

<script lang="ts">
import {
  IonText, IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
  IonSlides,
  IonSlide
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductUpsellsContainer',
  components: {IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSlides, IonSlide, IonImg},
  props: ['upsells'],
})
</script>

<style lang="scss" scoped>
ion-card-title {
  font-size: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

h2 {
  margin-top: 40px;
}
</style>
