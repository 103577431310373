
import {
  IonList,
  IonItem,
  IonItemGroup,
  IonRadioGroup,
  IonRadio,
  IonCheckbox,
  IonItemDivider,
  IonLabel
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductAddonsList',
  components: {IonList, IonItem, IonItemGroup, IonRadioGroup, IonRadio, IonCheckbox, IonItemDivider, IonLabel},
  props: ['addons']
})
