
import {
  IonText, IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
  IonSlides,
  IonSlide
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductUpsellsContainer',
  components: {IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSlides, IonSlide, IonImg},
  props: ['upsells'],
})
