<template>
  <ion-list lines="full">
    <ion-item-group v-for="addon in addons" :key="+addon.id">
      <ion-item-divider class="border-bottom-thin" sticky>
        <ion-label>{{ addon.label }}</ion-label>
        <!--<span slot="end" color="light" v-if="+addon.required" style="font-size: 0.5em">Obligatorio</span>-->
      </ion-item-divider>

      <template v-if="addon.type === 'checkbox'">
        <ion-item v-for="option in addon.options" :key="option.label">
          <ion-label>{{ option.label }}</ion-label>
          <ion-checkbox color="primary" slot="end" v-model="option.selected"></ion-checkbox>
        </ion-item>
      </template>

      <ion-radio-group v-model="addon.selected" v-if="addon.type === 'radio'">
        <ion-item v-for="option in addon.options" :key="option">
          <ion-label>{{ option }}</ion-label>
          <ion-radio slot="end" :value="option"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemGroup,
  IonRadioGroup,
  IonRadio,
  IonCheckbox,
  IonItemDivider,
  IonLabel
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductAddonsList',
  components: {IonList, IonItem, IonItemGroup, IonRadioGroup, IonRadio, IonCheckbox, IonItemDivider, IonLabel},
  props: ['addons']
})
</script>

<style scoped>
ion-item-divider {
  --background: #f9f9f9;
  --padding-top: 20px
}

ion-item-divider, ion-item {
  font-size: 15px;
}
</style>
