<template>
  <ion-page>
    <page-header :title="category"/>
    <ion-content class="background-light">
      <template v-if="product.data.id">
        <ion-img class='product-img' :src="product.data.images[0].src" :onIonImgDidLoad="product.imageLoaded = true"/>
        <ion-skeleton-text animated v-if="!product.imageLoaded" class='product-img'
                           :style="`height: ${windowWidth}px; box-shadow: inset 0 -40px 40px -20px #f9f9f9 !important;`"/>
        <ion-card style="margin-top: -50px">
          <ion-card-header>
            <ion-card-title>{{ product.data.name }}</ion-card-title>
            <ion-card-subtitle>{{ $currency(product.data.price) }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <p v-html="product.data.short_description"></p>
          </ion-card-content>
        </ion-card>
        <product-addons-list :addons="product.addons"/>
        <product-upsells-container v-if="product.upsells.length" :upsells="product.upsells"/>
      </template>

      <template v-else>
        <ion-skeleton-text animated class='product-img'
                           :style="`height: ${windowWidth}px; box-shadow: inset 0 -40px 40px -20px #f9f9f9 !important;`"/>
        <ion-card style="margin-top: -50px">
          <ion-card-header>
            <ion-card-title>
              <ion-skeleton-text animated style="width: 100%; height: 34px"/>
            </ion-card-title>
            <ion-card-subtitle>
              <ion-skeleton-text animated style="width: 15%; height: 14px"/>
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-skeleton-text animated style="width: 90%; height: 14px"/>
            <ion-skeleton-text animated style="width: 85%; height: 14px"/>
            <ion-skeleton-text animated style="width: 92%; height: 14px"/>
            <ion-skeleton-text animated style="width: 60%; height: 14px"/>
          </ion-card-content>
        </ion-card>
        <ion-list lines="full">
          <ion-item-group v-for="i in 2" :key="i">
            <ion-item-divider class="border-bottom-thin" sticky>
              <ion-label>
                <ion-skeleton-text animated style="width: 100px; height: 14px"/>
              </ion-label>
            </ion-item-divider>
            <ion-item>
              <ion-label>
                <ion-skeleton-text animated style="width: 180px; height: 14px"/>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <ion-skeleton-text animated style="width: 140px; height: 14px"/>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <ion-skeleton-text animated style="width: 150px; height: 14px"/>
              </ion-label>
            </ion-item>
          </ion-item-group>
        </ion-list>
      </template>
    </ion-content>
    <ion-footer>
      <ion-button expand="full" class="ion-no-margin"
                  @click="addToCart(product.data.id, product.data.name, product.data.price, product.data.images[0].src, product.data.short_description, product.addons)">
        Añadir al carrito
      </ion-button>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import ProductAddonsList from '@/components/Product/ProductAddonsList.vue';
import ProductUpsellsContainer from '@/components/ProductUpsellsContainer.vue';
import {
  IonPage, IonFooter, IonContent, IonImg, IonCard,
  IonCardContent, IonCardHeader, IonCardSubtitle,
  IonCardTitle, IonSkeletonText, IonLabel, IonList,
  IonItem, IonItemDivider, IonItemGroup, IonButton,
  toastController
} from '@ionic/vue';
import { defineComponent, reactive } from 'vue';
import { getStoreProductBySlug, getStoreProducts } from '@/services/Api';
import cartModule from '@/store/modules/cart';
import { ProductAddonType, ProductType, CartItemAddonType, CartItemType } from "@/interfaces/types";
import { checkmarkDoneOutline, warningOutline } from "ionicons/icons";

export default defineComponent({
  name: 'Product',
  components: {
    ProductAddonsList, ProductUpsellsContainer, PageHeader,
    IonPage, IonFooter, IonContent, IonImg, IonCard,
    IonCardContent, IonCardHeader, IonCardSubtitle,
    IonCardTitle, IonSkeletonText, IonLabel, IonList,
    IonItem, IonItemDivider, IonItemGroup, IonButton,
  },
  props: ['category', 'slug'],
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  mounted() {
    this.getProduct(this.$props.slug);
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    getProduct: function (slug: string) {
      getStoreProductBySlug(slug).then((data: ProductType[]) => {
        const product = data[0];
        this.product.data = product;

        this.prepareProductAddons(product.addons);

        getStoreProducts(product.upsell_ids).then((data) => this.product.upsells = data);
      });
    },
    prepareProductAddons: function (addons: ProductAddonType[]) {
      const addonsList: CartItemAddonType[] = [];

      addons.forEach((addon: ProductAddonType) => {
        const {id, label, type, options} = addon;

        if (type === 'checkbox') {
          const optionStatus: { label: string; selected: boolean }[] = options.label.map((label: string, index: number) => {
            return {label, selected: "default" in options && +options.default[0] === index}
          })
          addonsList.push({id: +id, label, type, options: optionStatus});
        } else if (type === 'radio') {
          addonsList.push({id: +id, label, type, options: options.label, selected: options.label[+options.default[0]]});
        }
      })

      addonsList.sort((a, b) => (a.id > b.id) ? 1 : -1);
      this.product.addons = addonsList;
    },
  },
  setup() {
    const reactiveData: { data: any; upsells: any; addons: CartItemAddonType[]; imageLoaded: boolean } = {
      data: {},
      upsells: [],
      addons: [],
      imageLoaded: false,
    }

    const product = reactive(reactiveData)

    async function openToast(message: string, error = false) {
      const header: string = error ? "Oops!" : "¡Genial!";
      const color: string | undefined = error ? "danger" : "success";
      const icon: string = error ? warningOutline : checkmarkDoneOutline;
      const toast = await toastController
        .create({
          message,
          header,
          color,
          translucent: false,
          position: "top",
          duration: 2000,
          buttons: [{
            side: "start",
            icon,
            role: "cancel",
          }]
        })
      return toast.present();
    }

    // TODO: [UI] Debounce this function (rdelcampo)
    function addToCart(id: number, name: string, price: string, image: string, description: string, addons: CartItemAddonType[]) {
      const cartObject: CartItemType = {
        id,
        price: +price,
        addons,
        meta: {
          name,
          description,
          image,
        }
      }

      cartModule.addToCart(cartObject).then(() => {
        openToast('El producto ha sido añadido al carrito.');
      }).catch((err) => {
        openToast(err || "Ha ocurrido un error inexperado.", true);
      });
    }

    return {
      addToCart,
      product,
    }
  },
})
</script>

<style scoped>
.product-img {
  width: 100%;
  margin: auto;
  display: block;
  box-shadow: 0 0 20px 10px #ffffff;
}

ion-item {
  font-size: 15px;
}

.item.active, .item.activated, .item-complex.active .item-content, .item-complex.activated .item-content, .item .item-content.active, .item .item-content.activated {
  border-color: #ccc;
  background-color: #ff0000;
}
</style>
